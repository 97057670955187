[
  {
    "name": "Mindset / Culture",
    "coord": {
      "latitude": {
        "min": 71.98,
        "max": 73.29
      },
      "longitude": {
        "min": -159,
        "max": -146
      }
    },
    "description": "The ideas, customs, and social behaviour of the environment the team works in.",
    "link": "./detail-pages/mindset-taxonomy.html",
    "deepLink": "mindset-taxonomy"
  },
  {
    "name": "Values",
    "coord": {
      "latitude": {
        "min": 69.48,
        "max": 70.04
      },
      "longitude": {
        "min": -156.64,
        "max": -150.05
      }
    },
    "description": "What we value.",
    "link": "./detail-pages/values-taxonomy.html",
    "deepLink": "values-taxonomy"
  },
  {
    "name": "Transparency",
    "coord": {
      "latitude": {
        "min": 75.46,
        "max": 75.87
      },
      "longitude": {
        "min": -168.33,
        "max": -154.84
      }
    },
    "description": "It is easy for others to see what actions are performed and why.",
    "link": "./detail-pages/tansperency-taxonomy.html",
    "deepLink": "tansperency-taxonomy"
  },
  {
    "name": "Work model",
    "coord": {
      "latitude": {
        "min": 74.14,
        "max": 75.09
      },
      "longitude": {
        "min": -149.39,
        "max": -142.93
      }
    },
    "description": "How we work.",
    "link": "./detail-pages/work-model-taxonomy.html",
    "deepLink": "work-model-taxonomy"
  },
  {
    "name": "Decision Making",
    "coord": {
      "latitude": {
        "min": 64.06,
        "max": 65.56
      },
      "longitude": {
        "min": -157.21,
        "max": -148.95
      }
    },
    "description": "When, where and how we make decisions.",
    "link": "./detail-pages/decision-making-taxonomy.html",
    "deepLink": "decision-making-taxonomy"
  },
  {
    "name": "Lean Thinking",
    "coord": {
      "latitude": {
        "min": 61.1,
        "max": 63.14
      },
      "longitude": {
        "min": -119,
        "max": -106
      }
    },
    "description": "Optimize flow of work items while respecting variation in the system.",
    "link": "./detail-pages/lean-thinking-taxonomy.html",
    "deepLink": "lean-thinking-taxonomy"
  },
  {
    "name": "Communication",
    "coord": {
      "latitude": {
        "min": 75.45,
        "max": 75.95
      },
      "longitude": {
        "min": -124.12,
        "max": -102.26
      }
    },
    "description": "Communication is the act of conveying intended meanings from one entity or group to another using mutually understood signs and semiotic rules.",
    "link": "./detail-pages/communication-taxonomy.html",
    "deepLink": "communication-taxonomy"
  },
  {
    "name": "Team Organisation",
    "coord": {
      "latitude": {
        "min": 59,
        "max": 61.38
      },
      "longitude": {
        "min": -82,
        "max": -62.64
      }
    },
    "description": "The team and its environment are set up in a way that they can work.",
    "link": "./detail-pages/team-organisation-taxonomy.html",
    "deepLink": "team-organisation-taxonomy"
  },
  {
    "name": "Team",
    "coord": {
      "latitude": {
        "min": 73.65,
        "max": 74.34
      },
      "longitude": {
        "min": -74.46,
        "max": -65.52
      }
    },
    "description": "People working together in a committed way to achieve a common goal or mission. The work is interdependent, and the team members share responsibility and hold themselves accountable for attaining the results. [MIT]",
    "link": "./detail-pages/team-taxonomy.html",
    "deepLink": "team-taxonomy"
  },
  {
    "name": "Composition",
    "coord": {
      "latitude": {
        "min": 78.55,
        "max": 78.85
      },
      "longitude": {
        "min": -75.77,
        "max": -63.7
      }
    },
    "description": "How we staff the product team.",
    "link": "./detail-pages/composition-taxonomy.html",
    "deepLink": "composition-taxonomy"
  },
  {
    "name": "Collaboration",
    "coord": {
      "latitude": {
        "min": 72.22,
        "max": 72.7
      },
      "longitude": {
        "min": -76.88,
        "max": -62.49
      }
    },
    "description": "How the product team works together to build the product.",
    "link": "./detail-pages/collaboration-taxonomy.html",
    "deepLink": "collaboration-taxonomy"
  },
  {
    "name": "Product Discovery",
    "coord": {
      "latitude": {
        "min": 83.1,
        "max": 83.61
      },
      "longitude": {
        "min": -80.31,
        "max": -66.17
      }
    },
    "description": "What product can we build that has so much value that the customer/user is willing to pay for?",
    "link": "./detail-pages/product-discovery-taxonomy.html",
    "deepLink": "product-discovery-taxonomy"
  },
  {
    "name": "Product Production",
    "coord": {
      "latitude": {
        "min": 79.0,
        "max": 79.6
      },
      "longitude": {
        "min": -40.12,
        "max": -2
      }
    },
    "description": "Build the product.",
    "link": "./detail-pages/product-production-taxonomy.html",
    "deepLink": "product-production-taxonomy"
  },
  {
    "name": "Preconditions",
    "coord": {
      "latitude": {
        "min": 82.28,
        "max": 82.57
      },
      "longitude": {
        "min": -56.3,
        "max": -41.83
      }
    },
    "description": "Conditions a team must discuss and agree upon in advance.",
    "link": "./detail-pages/preconditions-taxonomy.html",
    "deepLink": "preconditions-taxonomy"
  },
  {
    "name": "Environment",
    "coord": {
      "latitude": {
        "min": 82.85,
        "max": 83.14
      },
      "longitude": {
        "min": -3.89,
        "max": 9.05
      }
    },
    "description": "How to deal with the surroundings of the product team.",
    "link": "./detail-pages/environment-taxonomy.html",
    "deepLink": "environment-taxonomy"
  },
  {
    "name": "Process",
    "coord": {
      "latitude": {
        "min": 82.28,
        "max": 82.56
      },
      "longitude": {
        "min": -29.3,
        "max": -21.29
      }
    },
    "description": "The steps, decisions and actions we make during product production.",
    "link": "./detail-pages/process-taxonomy.html",
    "deepLink": "process-taxonomy"
  },
  {
    "name": "Architecture & Design",
    "coord": {
      "latitude": {
        "min": 78.36,
        "max": 78.79
      },
      "longitude": {
        "min": -25.2,
        "max": -3.73
      }
    },
    "description": "Design the parts of the product and their interactions.",
    "link": "./detail-pages/architecture-and-design-taxonomy.html",
    "deepLink": "architecture-and-design-taxonomy"
  },
  {
    "name": "Code Quality",
    "coord": {
      "latitude": {
        "min": 79.34,
        "max": 79.7
      },
      "longitude": {
        "min": 9.66,
        "max": 22.3
      }
    },
    "description": "How easily the developers can interact with the code base.",
    "link": "./detail-pages/code-quality-taxonomy.html",
    "deepLink": "code-quality-taxonomy"
  },
  {
    "name": "Test Driven",
    "coord": {
      "latitude": {
        "min": 68,
        "max": 68.7
      },
      "longitude": {
        "min": -2.7,
        "max": 8.5
      }
    },
    "description": "Test first development is a software development process where automated tests are written before code. This shift can result in much higher quality software and greater efficiencies.",
    "link": "./detail-pages/test-driven-taxonomy.html",
    "deepLink": "test-driven-taxonomy"
  },
  {
    "name": "Test After",
    "coord": {
      "latitude": {
        "min": 71.9,
        "max": 72.39
      },
      "longitude": {
        "min": -41,
        "max": -30.96
      }
    },
    "description": "Tests are written after the production code was written to increase confidence that the code behaves correctly.",
    "link": "./detail-pages/test-after-taxonomy.html",
    "deepLink": "test-after-taxonomy"
  },
  {
    "name": "Usability Validation",
    "coord": {
      "latitude": {
        "min": 63.98,
        "max": 65.49
      },
      "longitude": {
        "min": -29.61,
        "max": -19.02
      }
    },
    "description": "Validate the intended use with sample users.",
    "link": "./detail-pages/usability-validation-taxonomy.html",
    "deepLink": "usability-validation-taxonomy"
  },
  {
    "name": "Non-Functional Tests",
    "coord": {
      "latitude": {
        "min": 59.26,
        "max": 61.19
      },
      "longitude": {
        "min": -28.36,
        "max": -12.83
      }
    },
    "description": "Test the way software operates, not specific functionality.",
    "link": "./detail-pages/non-functional-tests-taxonomy.html",
    "deepLink": "non-functional-tests-taxonomy"
  },
  {
    "name": "Monitoring",
    "coord": {
      "latitude": {
        "min": 64.08,
        "max": 64.79
      },
      "longitude": {
        "min": 18.3,
        "max": 29.5
      }
    },
    "description": "Define, measure and react to metrics on the production environment.",
    "link": "./detail-pages/monitoring-taxonomy.html",
    "deepLink": "monitoring-taxonomy"
  },
  {
    "name": "Product Release",
    "coord": {
      "latitude": {
        "min": 65.37,
        "max": 67.16
      },
      "longitude": {
        "min": 51,
        "max": 63
      }
    },
    "description": "Ship the product to the customers and users.",
    "link": "./detail-pages/product-release-taxonomy.html",
    "deepLink": "product-release-taxonomy"
  }
]
