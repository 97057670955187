import L from "leaflet"
import "./node_modules/leaflet/dist/leaflet.css"
import topicsData from "./topics-data.json"
import attributesData from "./attributes-data.json"
import taxonomyData from "./taxonomy-data.json"

import leaflet_rastercoords from "leaflet-rastercoords";

L.RasterCoords = leaflet_rastercoords

const DETAIL_ID = 'detail';
const HIGHLIGHTED_CLASS = 'highlighted-item';
const SELECTED_CLASS = 'selected-item';

const img = [11811, 5964]; // orginal width, height of image

const map = L.map("map", {
    minZoom: 0,
    maxZoom: 6,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
    wheelPxPerZoomLevel: 200,
});

const rc = new L.RasterCoords(map, img);

const southWest = L.latLng(44, -180);
const northEast = L.latLng(85, 80);
const tileLoadBounds = L.latLngBounds(southWest, northEast);

const selectedClassName = "selected-item";

let timeout = null;

L.tileLayer(
    "https://qualitydata.blob.core.windows.net/map-data-v2/{z}/{x}/{y}.png",
    {
        noWrap: true,
        attribution:
            'Quality Map by <a href="https://bbv.ch" rel="noopener noreferrer" target="_blank">bbv.ch</a> under <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA</a> | Topic content from uncounted sources <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=vYWZJ3cgnU2Xl0IjjPwG4oUTaT2riEBHiDx1ic2olvZUOTczV0dXS082T0JYQUFBRlhYTVdWWk9EMS4u" rel="noopener noreferrer" target="_blank">feedback/citation request</a>',
        tileSize: 256,
        bounds: tileLoadBounds,
        crossOrigin: "anonymous",
    }
).addTo(map);

map.setView(rc.unproject([img[0], img[1]]), 2);
map.fitBounds(tileLoadBounds);

const markedTopics = markClickableAreas(map, [...taxonomyData, ...topicsData, ...attributesData]);

function closeDetailView() {
    document.getElementById("detail").style.display = "none";
    clearSelected();
    map.closePopup();
    map.invalidateSize();
    history.pushState(null, '', window.location.href.split('#')[0]);
}

function markClickableAreas(map, topics) {
    return topics.map(({coord, link, deepLink, description, name}) => {
        const {latitude, longitude} = coord;
        const polygon = L.polygon(
            [
                [latitude.max, longitude.min],
                [latitude.max, longitude.max],
                [latitude.min, longitude.max],
                [latitude.min, longitude.min],
            ],
            {
                className: "topic",
            }
        ).addTo(map);

        polygon.on("click", () => onPolygonClick({coord, link, name, polygon, deepLink, description}));

        return {coord, link, name, polygon, deepLink, description};
    });
}

function openDetailView(page) {
    document.getElementById(DETAIL_ID).style.display = "block";
    document.getElementById(DETAIL_ID).scrollTop = 0;
    map.invalidateSize();
    map.panTo([page.coord.latitude.min, page.coord.longitude.min]);
    clearSelected();

    const selectedPolygon = map._layers[L.Util.stamp(page.polygon)];
    if (selectedPolygon) {
        L.DomUtil.addClass(selectedPolygon._path, SELECTED_CLASS);
    }
}

function openPointFromLocationHash() {
    const pointLinkFragment = window.location.hash ? window.location.hash.substr(1) : '';
    if (pointLinkFragment) {
        const page = markedTopics.find(t => t.deepLink === pointLinkFragment);
        if (page) {
            openPage(page);
            openDetailView(page)
        } else {
            window.location.replace(window.location.origin);
        }
    } else {
        closeDetailView();
    }
}

function onPolygonClick(topic) {
    openPage(topic);
    openDetailView(topic)

    if (topic.deepLink) {
        history.pushState(topic.deepLink, '', '#' + topic.deepLink);
    }
}

function openPage(topic) {
    fetch(topic.link)
        .then((data) => data.text())
        .then(
            (html) => (document.getElementById("detail-content").innerHTML = html)
        );
}

function clearSelected() {
    const selectedElement = document.getElementsByClassName(selectedClassName)[0];
    if (selectedElement) {
        L.DomUtil.removeClass(selectedElement, selectedClassName);
    }
}

function searchTopics(topics, searchTerm) {
    const lowerCaseSearchTerm = searchTerm.replace(/\s/g, '').toLowerCase();
    return topics.filter((topic) =>
        topic.name.toLowerCase().includes(lowerCaseSearchTerm) ||
        topic.description.toLowerCase().includes(lowerCaseSearchTerm)
    );
}

function highlightResults(results) {
    results.forEach(result => {
        L.DomUtil.addClass(result.polygon._path, HIGHLIGHTED_CLASS);
    });
}

function unhighlightResults(topics) {
    topics.forEach(topic => {
        L.DomUtil.removeClass(topic.polygon._path, HIGHLIGHTED_CLASS);
    });
}

['search', 'keyup'].map((event) => {
    document.getElementById('search-input').addEventListener(event, function (e) {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            unhighlightResults(markedTopics);
            const searchTerm = e.target.value;

            if (searchTerm.length > 2) {
                const results = searchTopics(markedTopics, searchTerm);

                highlightResults(results);
            }
        }, 500);
    });
});

document.getElementById("close-button").onclick = () => closeDetailView();

document.getElementById("page").onkeyup = (event) => {
    if (event.key === "Escape") {
        closeDetailView();
    }
};

document.onclick = (event) => {
    if (!event.target.matches("#menu")) {
        document.getElementById("menu").checked = false;
    }
};

document.getElementById('search-icon').onclick = () => toggleSearch();

function toggleSearch(){
    const input = document.getElementById('search-input');
    const icon = document.getElementById('search-icon');
    if (input.classList.contains('active')) {
        icon.classList.remove('active');
        input.classList.remove('active');

    } else {
        input.classList.add('active');
        icon.classList.add('active')

    }
}

document.getElementById('search-input').onblur = (e) => {
    if(e.target.value.length === 0){
        toggleSearch();
    }
}
window.addEventListener('DOMContentLoaded', openPointFromLocationHash);
window.addEventListener('popstate', openPointFromLocationHash);
