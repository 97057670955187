[
  {
    "name": "All goals visible",
    "coord": {
      "latitude": {
        "min": 76.2,
        "max": 77.5
      },
      "longitude": {
        "min": -167,
        "max": -160.6
      }
    },
    "description": "Product goals, team goals and individual goals are visible to everyone. Transparency helps alignment and empathy.",
    "link": "./detail-pages/all-goals-visible.html",
    "deepLink": "all-goals-visible"
  },
  {
    "name": "All Work Visible",
    "coord": {
      "latitude": {
        "min": 74.09,
        "max": 75.09
      },
      "longitude": {
        "min": -164.04,
        "max": -155.74
      }
    },
    "description": "Everything everyone is working on is visible to everyone. Visibility leads to better decision making and fewer surprises.",
    "link": "./detail-pages/all-work-visible.html",
    "deepLink": "all-work-visible"
  },
  {
    "name": "Idle time (Slack Time)",
    "coord": {
      "latitude": {
        "min": 77.4,
        "max": 78.6
      },
      "longitude": {
        "min": -153.3,
        "max": -146
      }
    },
    "description": "Having idle time is essential to be able to respond to changes quickly, have time to retrospect, learn and be creative.",
    "link": "./detail-pages/idle-time.html",
    "deepLink": "idle-time"
  },
  {
    "name": "Small Steps",
    "coord": {
      "latitude": {
        "min": 76.97,
        "max": 76.53
      },
      "longitude": {
        "min": -142.1,
        "max": -151.9
      }
    },
    "description": "Everything we do, we do in small and complete steps by applying plan-do-check-act (PDCA) to reduce risks of doing the wrong thing.",
    "link": "./detail-pages/small-steps.html",
    "deepLink": "small-steps"
  },
  {
    "name": "Sustainable Pace",
    "coord": {
      "latitude": {
        "min": 75.4,
        "max": 76.36
      },
      "longitude": {
        "min": -152.18,
        "max": -142.3
      }
    },
    "description": "Working at a sustainable pace allows the team to keep a high level of quality forever. Building quality in leads to a sustainable pace (no fire-fighting, no staling).",
    "link": "./detail-pages/sustainable-pace.html",
    "deepLink": "sustainable-pace"
  },
  {
    "name": "Lean Startup",
    "coord": {
      "latitude": {
        "min": 75.7,
        "max": 76.6
      },
      "longitude": {
        "min": -140.49,
        "max": -133
      }
    },
    "description": "",
    "link": "./detail-pages/lean-startup.html",
    "deepLink": "lean-startup"
  },
  {
    "name": "Dev Ops",
    "coord": {
      "latitude": {
        "min": 74.55,
        "max": 75.55
      },
      "longitude": {
        "min": -138.49,
        "max": -132.78
      }
    },
    "description": "DevOps proposes close collaboration between Development and Operations in cross-functional teams. Both work with the same tools and apply the same practices and methods. Together with a strong focus on automation and monitoring, this leads to shorter development cycles, more frequent and dependable releases, closely aligned with business objectives.",
    "link": "./detail-pages/devops.html",
    "deepLink": "devops"
  },
  {
    "name": "Positive error culture",
    "coord": {
      "latitude": {
        "min": 71.2,
        "max": 73.5
      },
      "longitude": {
        "min": -160.2,
        "max": -165.95
      }
    },
    "description": "A culture of failure - instead of a culture of blame - focuses on learning from failure instead of preventing failure. Thus, leading to innovation by encouraging people to achieve difficult goals.",
    "link": "./detail-pages/positive-error-culture.html",
    "deepLink": "positive-error-culture"
  },
  {
    "name": "Psychological safety",
    "coord": {
      "latitude": {
        "min": 69.3,
        "max": 71.1
      },
      "longitude": {
        "min": -158,
        "max": -166.5
      }
    },
    "description": "An environment build upon trust is one of the core building blocks for a high performing team.",
    "link": "./detail-pages/psychological-safety.html",
    "deepLink": "psychological-safety"
  },
  {
    "name": "Respect",
    "coord": {
      "latitude": {
        "min": 70.9,
        "max": 71.55
      },
      "longitude": {
        "min": -146.4,
        "max": -139.07
      }
    },
    "description": "We are honest and open in our thinking. Our credo is that valuing diversity leads us to the best solutions.",
    "link": "./detail-pages/respect.html",
    "deepLink": "respect"
  },
  {
    "name": "Product Focus",
    "coord": {
      "latitude": {
        "min": 69.22,
        "max": 70.52
      },
      "longitude": {
        "min": -149.11,
        "max": -141.53
      }
    },
    "description": "A holistic view of the whole lifetime of the product leads to globally optimised solutions rather than local sub-optimization, often introduced by thinking in projects and phases.",
    "link": "./detail-pages/product-focus.html",
    "deepLink": "product-focus"
  },
  {
    "name": "Diversity and Inclusion",
    "coord": {
      "latitude": {
        "min": 67.72,
        "max": 69.01
      },
      "longitude": {
        "min": -166.51,
        "max": -155.66
      }
    },
    "description": "Workplace diversity is understanding, accepting, and valuing differences between people. Inclusion is a collaborative, supportive, and respectful environment that increases the participation and contribution of all employees.",
    "link": "./detail-pages/diversity-and-inclusion.html",
    "deepLink": "diversity-and-inclusion"
  },
  {
    "name": "Simplicity",
    "coord": {
      "latitude": {
        "min": 66.57,
        "max": 67.44
      },
      "longitude": {
        "min": -165.55,
        "max": -156.88
      }
    },
    "description": "The state or quality of being simple - easy to understand or explain. Consistent, small in size with few interactions consisting of few concept and elements.",
    "link": "./detail-pages/simplicity.html",
    "deepLink": "simplicity"
  },
  {
    "name": "Decision Making Methods",
    "coord": {
      "latitude": {
        "min": 65.05,
        "max": 67.33
      },
      "longitude": {
        "min": -147.08,
        "max": -139.3
      }
    },
    "description": "How we decide in what situation: consensus, consent, Roman vote, consultative decision by a single person, decide-and-inform.",
    "link": "./detail-pages/decision-making-methods.html",
    "deepLink": "decision-making-methods"
  },
  {
    "name": "Empirical Decision Making",
    "coord": {
      "latitude": {
        "min": 62.78,
        "max": 65.94
      },
      "longitude": {
        "min": -165.39,
        "max": -159.8
      }
    },
    "description": "Decisions are made based on historical data, not feelings and predictions.",
    "link": "./detail-pages/empirical-decision-making.html",
    "deepLink": "empirical-decision-making"
  },
  {
    "name": "Defer Decisions",
    "coord": {
      "latitude": {
        "min": 61.71,
        "max": 63.32
      },
      "longitude": {
        "min": -153,
        "max": -144.5
      }
    },
    "description": "Decide only what needs a decision now. Use the time to learn and make better informed decisions later.",
    "link": "./detail-pages/defer-decision.html",
    "deepLink": "defer-decision"
  },
  {
    "name": "Decision Making Roles",
    "coord": {
      "latitude": {
        "min": 58.65,
        "max": 61.44
      },
      "longitude": {
        "min": -162.87,
        "max": -155.17
      }
    },
    "description": "Who decides what: team, individual, position in the hierarchy.",
    "link": "./detail-pages/decision-making-roles.html",
    "deepLink": "decision-making-roles"
  },
  {
    "name": "Decision Log",
    "coord": {
      "latitude": {
        "min": 59,
        "max": 61
      },
      "longitude": {
        "min": -153,
        "max": -145.5
      }
    },
    "description": "",
    "link": "./detail-pages/decision-log.html",
    "deepLink": "decision-log"
  },
  {
    "name": "Communication Map",
    "coord": {
      "latitude": {
        "min": 76.4,
        "max": 77
      },
      "longitude": {
        "min": -115.7,
        "max": -99.1
      }
    },
    "description": "A map showing the (desired) flow of information from producers to consumers.",
    "link": "./detail-pages/communication-map.html",
    "deepLink": "communication-map"
  },
  {
    "name": "Constructive Feedback ",
    "coord": {
      "latitude": {
        "min": 77.3,
        "max": 78.05
      },
      "longitude": {
        "min": -125.83,
        "max": -115.49
      }
    },
    "description": "Focus on specific behaviours rather than making general statements. Keep feedback impersonal and goal-oriented. Offer feedback as soon after the action as possible.",
    "link": "./detail-pages/constructive-feedback.html",
    "deepLink": "constructive-feedback"
  },
  {
    "name": "Active Listening",
    "coord": {
      "latitude": {
        "min": 73.85,
        "max": 74.8
      },
      "longitude": {
        "min": -125.5,
        "max": -117
      }
    },
    "description": "Effective listening is active, requiring the hearer to “get inside the head” of the speaker so that he or she can understand the communication from the speaker's point of view.",
    "link": "./detail-pages/active-listening.html",
    "deepLink": "active-listening"
  },
  {
    "name": "Conflict Management",
    "coord": {
      "latitude": {
        "min": 72.3,
        "max": 73.4
      },
      "longitude": {
        "min": -114.78,
        "max": -103
      }
    },
    "description": "The process of limiting the negative aspects of conflict while increasing the positive aspects of conflict. The aim of conflict management is to enhance learning and group outcomes.",
    "link": "./detail-pages/conflict-management.html",
    "deepLink": "conflict-management"
  },
  {
    "name": "Facts Over Assumptions",
    "coord": {
      "latitude": {
        "min": 73.88,
        "max": 74.8
      },
      "longitude": {
        "min": -111.2,
        "max": -99.64
      }
    },
    "description": "Transform assumption into facts and make the facts visible.",
    "link": "./detail-pages/facts-over-assumptions.html",
    "deepLink": "facts-over-assumptions"
  },
  {
    "name": "(Almost) No Hand-offs",
    "coord": {
      "latitude": {
        "min": 64.18,
        "max": 66.58
      },
      "longitude": {
        "min": -124.76,
        "max": -115.8
      }
    },
    "description": "Handoffs - passing work from one person to another person - hinder flow of work. They introduce delays, additional pick-up time (time needed to start working), and high risk of miscommunication (single point of communication instead of continuous communication). Thus, resulting in lower quality and higher cycle time.",
    "link": "./detail-pages/almost-no-hand-offs.html",
    "deepLink": "almost-no-hand-offs"
  },
  {
    "name": "Low WiP & WiP Limits",
    "coord": {
      "latitude": {
        "min": 63.7,
        "max": 67
      },
      "longitude": {
        "min": -110.12,
        "max": -103.84
      }
    },
    "description": "Work in Progress (WiP) is the number of tasks in progress. Limiting the WiP shortens feedback cycles, reduces coordination overhead and the risk of delays.",
    "link": "./detail-pages/low-wip-limits.html",
    "deepLink": "low-wip-limits"
  },
  {
    "name": "Low Number of Queues",
    "coord": {
      "latitude": {
        "min": 58.57,
        "max": 60.5
      },
      "longitude": {
        "min": -114.24,
        "max": -103.18
      }
    },
    "description": "Queues (work is waiting to be picked up by the next process step) hinder flow of work because they introduce delays, and pick-up time (time needed to start working). Thus, resulting in longer cycle times, and higher WiP.",
    "link": "./detail-pages/low-number-of-queues.html",
    "deepLink": "low-number-of-queues"
  },
  {
    "name": "Pull System",
    "coord": {
      "latitude": {
        "min": 66.72,
        "max": 69.05
      },
      "longitude": {
        "min": -121.6,
        "max": -115.53
      }
    },
    "description": "Steps later in the process pull work from former steps. Pull systems reduce waste in the system and allow work to be delivered just-in-time.",
    "link": "./detail-pages/pull-system.html",
    "deepLink": "pull-system"
  },
  {
    "name": "Feedback Cycles",
    "coord": {
      "latitude": {
        "min": 61.84,
        "max": 64.7
      },
      "longitude": {
        "min": -83.73,
        "max": -76.89
      }
    },
    "description": "Establish feedback cycles in processes and organisational structures so that they are as short as possible to be able to inspect and adapt to the progress of the development of the product. Thus, reducing time, cost and risks. ",
    "link": "./detail-pages/feedback-cycles.html",
    "deepLink": "feedback-cycles"
  },
  {
    "name": "Organizing Teams",
    "coord": {
      "latitude": {
        "min": 61.6,
        "max": 63.4
      },
      "longitude": {
        "min": -73.5,
        "max": -64
      }
    },
    "description": "",
    "link": "./detail-pages/organizing-teams.html",
    "deepLink": "organizing-teams"
  },
  {
    "name": "Continuous Learning & Improvement",
    "coord": {
      "latitude": {
        "min": 53,
        "max": 56.1
      },
      "longitude": {
        "min": -74.48,
        "max": -63.45
      }
    },
    "description": "Individuals and the team invest continuously in learning to be able to adapt to changes in the environment and the team. The team is supported by mentoring and coaching.",
    "link": "./detail-pages/continuous-learning-and-improvement.html",
    "deepLink": "continuous-learning-and-improvement"
  },
  {
    "name": "Communities (of Practices)",
    "coord": {
      "latitude": {
        "min": 58.9,
        "max": 61.68
      },
      "longitude": {
        "min": -61.8,
        "max": -53.43
      }
    },
    "description": "A group of people who share a craft or profession. They exchange knowledge and improve their skills together.",
    "link": "./detail-pages/communities.html",
    "deepLink": "communities"
  },
  {
    "name": "Document Management",
    "coord": {
      "latitude": {
        "min": 54.39,
        "max": 57.89
      },
      "longitude": {
        "min": -85.96,
        "max": -77
      }
    },
    "description": "Track, manage and store documents so that all involved people have proper access and traceability.",
    "link": "./detail-pages/document-management.html",
    "deepLink": "document-management"
  },
  {
    "name": "Team Alignment & Team Autonomy",
    "coord": {
      "latitude": {
        "min": 75.3,
        "max": 77.5
      },
      "longitude": {
        "min": -89,
        "max": -81
      }
    },
    "description": "Everyone works towards the same goal(s) and vision.",
    "link": "./detail-pages/team-alignment-and-autonomy.html",
    "deepLink": "team-alignment-and-autonomy"
  },
  {
    "name": "Team End-to-End Responsibility",
    "coord": {
      "latitude": {
        "min": 74.9,
        "max": 76.79
      },
      "longitude": {
        "min": -61.5,
        "max": -52.5
      }
    },
    "description": "The product team as a whole is responsible for all activities regarding the product: from ideas to operations. The team cannot delegate responsibility to others.",
    "link": "./detail-pages/team-end-to-end-responsibility.html",
    "deepLink": "team-end-to-end-responsibility"
  },
  {
    "name": "Cross-functional Teams",
    "coord": {
      "latitude": {
        "min": 78.2,
        "max": 79.3
      },
      "longitude": {
        "min": -87.8,
        "max": -78.7
      }
    },
    "description": "A team of members with different functional expertise and from all levels of an organisation. The team should ideally have all skills needed to build and operate the product on its own.",
    "link": "./detail-pages/cross-functional-teams.html",
    "deepLink": "cross-functional-teams"
  },
  {
    "name": "(T-/π-) M-Shaped Team Members",
    "coord": {
      "latitude": {
        "min": 75.5,
        "max": 76.8
      },
      "longitude": {
        "min": -75,
        "max": -64
      }
    },
    "description": "Team members have a few areas of deep expertise and general knowledge of all other topics.",
    "link": "./detail-pages/m-shaped-team-members.html",
    "deepLink": "m-shaped-team-members"
  },
  {
    "name": "Collaboration on whole value stream",
    "coord": {
      "latitude": {
        "min": 69.14,
        "max": 71.9
      },
      "longitude": {
        "min": -86,
        "max": -76.21
      }
    },
    "description": "Collaboration happens between everybody on the whole value stream.",
    "link": "./detail-pages/collaboration-on-whole-value-stream.html",
    "deepLink": "collaboration-on-whole-value-stream"
  },
  {
    "name": "Collective (Code) Ownership",
    "coord": {
      "latitude": {
        "min": 66.93,
        "max": 69.9
      },
      "longitude": {
        "min": -73,
        "max": -65
      }
    },
    "description": "Collective Ownership encourages everyone to contribute new ideas to all segments of the product. Anyone can change anything to add functionality, fix bugs, improve designs or refactor. No one person becomes a bottleneck for changes.",
    "link": "./detail-pages/collective-code-ownership.html",
    "deepLink": "collective-code-ownership"
  },
  {
    "name": "Retrospectives",
    "coord": {
      "latitude": {
        "min": 71.1,
        "max": 71.93
      },
      "longitude": {
        "min": -72.85,
        "max": -60.61
      }
    },
    "description": "Inspect and adapt the way the team works.",
    "link": "./detail-pages/retrospectives.html",
    "deepLink": "retrospectives"
  },
  {
    "name": "Shared Task Responsibility",
    "coord": {
      "latitude": {
        "min": 69.3,
        "max": 70.6
      },
      "longitude": {
        "min": -65.3,
        "max": -53.5
      }
    },
    "description": "The whole team – not an individual – is responsible for the outcome of a task. No blame game but helping each other out.",
    "link": "./detail-pages/shared-task-responsibility.html",
    "deepLink": "shared-task-responsibility"
  },
  {
    "name": "Team Charter",
    "coord": {
      "latitude": {
        "min": 74,
        "max": 74.7
      },
      "longitude": {
        "min": -88.26,
        "max": -77
      }
    },
    "description": "The team defines the guidelines and boundaries of how they want to work together: team purpose, scope, goals, …",
    "link": "./detail-pages/team-charter.html",
    "deepLink": "team-charter"
  },
  {
    "name": "User/Customer Research",
    "coord": {
      "latitude": {
        "min": 81.05,
        "max": 81.82
      },
      "longitude": {
        "min": -85.69,
        "max": -77.43
      }
    },
    "description": "Gather user inputs via support cases, surveys, user diaries, job shadowing or contextual inquiries.",
    "link": "./detail-pages/user-customer-research.html",
    "deepLink": "user-customer-research"
  },
  {
    "name": "Business Model Canvas",
    "coord": {
      "latitude": {
        "min": 81.29,
        "max": 81.79
      },
      "longitude": {
        "min": -72.5,
        "max": -61.25
      }
    },
    "description": "Tool to visualise components of a business model in a visual structure to design, discuss and analyse new or existing business models. It generates a shared understanding and helps to make decisions while helping to ensure that nothing vital is forgotten.",
    "link": "./detail-pages/business-model-canvas.html",
    "deepLink": "business-model-canvas"
  },
  {
    "name": "Product Prototype",
    "coord": {
      "latitude": {
        "min": 82.11,
        "max": 82.86
      },
      "longitude": {
        "min": -83.93,
        "max": -77.16
      }
    },
    "description": "An early sample, model or release of a product to test a concept or process or to act as a thing to be learned from.",
    "link": "./detail-pages/product-prototype.html",
    "deepLink": "product-prototype"
  },
  {
    "name": "Empathy Map",
    "coord": {
      "latitude": {
        "min": 82.12,
        "max": 82.6
      },
      "longitude": {
        "min": -73.91,
        "max": -65.91
      }
    },
    "description": "Tool to help teams develop deep, shared understanding for and empathy with their customer needs and desires as a means to improve the product and service.",
    "link": "./detail-pages/empathy-map.html",
    "deepLink": "empathy-map"
  },
  {
    "name": "Product Vision",
    "coord": {
      "latitude": {
        "min": 82.59,
        "max": 83.03
      },
      "longitude": {
        "min": -65.52,
        "max": -58.05
      }
    },
    "description": "A clear, concise description of a company’s long-term aspirational goal for its product. Everybody involved should be able to tell why we’re creating the product and the shared objective.",
    "link": "./detail-pages/product-vision.html",
    "deepLink": "product-vision"
  },
  {
    "name": "Design Studio",
    "coord": {
      "latitude": {
        "min": 83.29,
        "max": 83.93
      },
      "longitude": {
        "min": -86.84,
        "max": -82.42
      }
    },
    "description": "A lean UX method that helps a team to come up with a lot of ideas to solve a well-framed problem.",
    "link": "./detail-pages/design-studio.html",
    "deepLink": "design-studio"
  },
  {
    "name": "Design Thinking",
    "coord": {
      "latitude": {
        "min": 83.79,
        "max": 84.41
      },
      "longitude": {
        "min": -76.08,
        "max": -69.87
      }
    },
    "description": "Design thinking is a method for practical, creative resolution of problems.",
    "link": "./detail-pages/design-thinking.html",
    "deepLink": "design-thinking"
  },
  {
    "name": "Customer Journey",
    "coord": {
      "latitude": {
        "min": 83.79,
        "max": 84.41
      },
      "longitude": {
        "min": -84,
        "max": -77
      }
    },
    "description": "",
    "link": "./detail-pages/customer-journey.html",
    "deepLink": "customer-journey"
  },
  {
    "name": "User Journey",
    "coord": {
      "latitude": {
        "min": 84,
        "max": 84.6
      },
      "longitude": {
        "min": -64.5,
        "max": -69.5
      }
    },
    "description": "",
    "link": "./detail-pages/user-journey.html",
    "deepLink": "user-journey"
  },
  {
    "name": "Service Blueprint",
    "coord": {
      "latitude": {
        "min": 84,
        "max": 84.6
      },
      "longitude": {
        "min": -56,
        "max": -62
      }
    },
    "description": "",
    "link": "./detail-pages/service-blueprint.html",
    "deepLink": "service-blueprint"
  },
  {
    "name": "Impact Mapping",
    "coord": {
      "latitude": {
        "min": 83.26,
        "max": 83.9
      },
      "longitude": {
        "min": -63.16,
        "max": -56.76
      }
    },
    "description": "Strategic planning technique based upon analysis of impacts on customers dependent on decision variants.",
    "link": "./detail-pages/impact-mapping.html",
    "deepLink": "impact-mapping"
  },
  {
    "name": "Systems Thinking",
    "coord": {
      "latitude": {
        "min": 83.25,
        "max": 83.65
      },
      "longitude": {
        "min": -7.2,
        "max": 2
      }
    },
    "description": "Concepts and tools to develop an understanding of the interdependent structures of dynamic systems. Helps individuals and teams to identify leverage points that lead to desired outcomes.",
    "link": "./detail-pages/system-thinking.html",
    "deepLink": "system-thinking"
  },
  {
    "name": "Standards",
    "coord": {
      "latitude": {
        "min": 83.25,
        "max": 83.5
      },
      "longitude": {
        "min": 2.5,
        "max": 11.5
      }
    },
    "description": "",
    "link": "./detail-pages/standards.html",
    "deepLink": "standards"
  },
  {
    "name": "Stakeholder Management",
    "coord": {
      "latitude": {
        "min": 82.2,
        "max": 82.7
      },
      "longitude": {
        "min": 5.5,
        "max": 17
      }
    },
    "description": "Create positive relationships with stakeholders through the appropriate management of their xpectations and agreed objectives.",
    "link": "./detail-pages/stakeholder-management.html",
    "deepLink": "stakeholder-management"
  },
  {
    "name": "Risk Management",
    "coord": {
      "latitude": {
        "min": 82.15,
        "max": 82.65
      },
      "longitude": {
        "min": -10.81,
        "max": 0.35
      }
    },
    "description": "Manage personnel, technical, cultural, timing, success risks by mitigating likelihood and cost in case of occurrence.",
    "link": "./detail-pages/risk-management.html",
    "deepLink": "risk-management"
  },
  {
    "name": "Continuous Delivery",
    "coord": {
      "latitude": {
        "min": 83.8,
        "max": 84.37
      },
      "longitude": {
        "min": -33.5,
        "max": -26
      }
    },
    "description": "Delivering software in short cycles by optimizing the value stream from idea to production. Reduces cost, time and risks of delivering.",
    "link": "./detail-pages/continuous-delivery.html",
    "deepLink": "continuous-delivery"
  },
  {
    "name": "Rolling Wave Planning",
    "coord": {
      "latitude": {
        "min": 82.7,
        "max": 83.15
      },
      "longitude": {
        "min": -26.4,
        "max": -15.6
      }
    },
    "description": "The process of project planning in waves as the project proceeds, and later details become clearer. This is an approach that iteratively plans for a project as it unfolds.",
    "link": "./detail-pages/rolling-wave-planning.html",
    "deepLink": "rolling-wave-planning"
  },
  {
    "name": "Decompose Work Vertically (by value)",
    "coord": {
      "latitude": {
        "min": 83.79,
        "max": 84.55
      },
      "longitude": {
        "min": -23.9,
        "max": -13.79
      }
    },
    "description": "We split the whole product into mostly independent pieces that provide value by themselves.",
    "link": "./detail-pages/decompose-work-vertically.html",
    "deepLink": "decompose-work-vertically"
  },
  {
    "name": "Zero Bug Policy",
    "coord": {
      "latitude": {
        "min": 83.23,
        "max": 83.65
      },
      "longitude": {
        "min": -36.73,
        "max": -28.78
      }
    },
    "description": "When a bug is found it is either fixed immediately or never. Don’t waste time managing bug lists and bugcascades.",
    "link": "./detail-pages/zero-bug-policy.html",
    "deepLink": "zero-bug-policy"
  },
  {
    "name": "Product Change Management",
    "coord": {
      "latitude": {
        "min": 83.23,
        "max": 83.65
      },
      "longitude": {
        "min": -21.5,
        "max": -9.3
      }
    },
    "description": "",
    "link": "./detail-pages/product-change-management.html",
    "deepLink": "product-change-management"
  },
  {
    "name": "Definition of Done",
    "coord": {
      "latitude": {
        "min": 82.5,
        "max": 82.93
      },
      "longitude": {
        "min": -39.33,
        "max": -30.62
      }
    },
    "description": "Each Team has its Definition of Done or consistent acceptance criteria across all work items. A Definition of Done drives the quality of work and is used to assess when a work item has been completed.",
    "link": "./detail-pages/definition-of-done.html",
    "deepLink": "definition-of-done"
  },
  {
    "name": "Definition of Ready",
    "coord": {
      "latitude": {
        "min": 81.14,
        "max": 81.75
      },
      "longitude": {
        "min": -35.5,
        "max": -27
      }
    },
    "description": "Having a Definition of Ready means that work items must be immediately actionable. The team must be able to determine what needs to be done. Ready work items should be clear, concise, and most importantly, actionable.",
    "link": "./detail-pages/definition-of-ready.html",
    "deepLink": "definition-of-ready"
  },
  {
    "name": "Root Cause Analysis",
    "coord": {
      "latitude": {
        "min": 81.17,
        "max": 81.75
      },
      "longitude": {
        "min": -22.28,
        "max": -12.5
      }
    },
    "description": "Solve the underlying problems, not symptoms.",
    "link": "./detail-pages/root-cause-analysis.html",
    "deepLink": "root-cause-analysis"
  },
  {
    "name": "Continuous Documentation",
    "coord": {
      "latitude": {
        "min": 80.32,
        "max": 80.95
      },
      "longitude": {
        "min": -34.32,
        "max": -22
      }
    },
    "description": "Documentation is created automatically as part of Continuous Delivery by combining manually built pieces and automatically extracted information from source code, configuration etc.",
    "link": "./detail-pages/continuous-documentation.html",
    "deepLink": "continuous-documentation"
  },
  {
    "name": "RACI Matrix",
    "coord": {
      "latitude": {
        "min": 80.2,
        "max": 80.84
      },
      "longitude": {
        "min": -20,
        "max": -13
      }
    },
    "description": "Describes the participation by various roles in completing tasks or deliverables. Responsible, Accountable, Consulted, Informed.",
    "link": "./detail-pages/raci-matrix.html",
    "deepLink": "raci-matrix"
  },
  {
    "name": "Hexagonal/Clean Architecture",
    "coord": {
      "latitude": {
        "min": 80.95,
        "max": 81.72
      },
      "longitude": {
        "min": -10,
        "max": 1.75
      }
    },
    "description": "In a clean architecture, dependencies between parts of the software (methods, classes, components) are directed from more concrete (e.g. system boundary) to more abstract pieces (e.g. business logic). Clean architecture leads to testability, flexibility, and independence from UI, DB, and other external agencies.",
    "link": "./detail-pages/clean-architecture.html",
    "deepLink": "clean-architecture"
  },
  {
    "name": "Modularization, Decomposition/Composition",
    "coord": {
      "latitude": {
        "min": 75.74,
        "max": 77.5
      },
      "longitude": {
        "min": -11.03,
        "max": -1.09
      }
    },
    "description": "The system is broken down into almost independent parts that are orchestrated to provide the system’s behaviour.",
    "link": "./detail-pages/modularisation-decomposition-composition.html",
    "deepLink": "modularisation-decomposition-composition"
  },
  {
    "name": "Evolutionary Architecture/Design",
    "coord": {
      "latitude": {
        "min": 79.85,
        "max": 80.82
      },
      "longitude": {
        "min": -7,
        "max": 4
      }
    },
    "description": "Evolutionary architecture and design support only today’s requirements but are open for adaptation for future needs. This leads to reduced development costs and time - now and in the future.",
    "link": "./detail-pages/evolutionary-architecture-designe.html",
    "deepLink": "evolutionary-architecture-designe"
  },
  {
    "name": "Design/Architecture options",
    "coord": {
      "latitude": {
        "min": 77.2,
        "max": 78.7
      },
      "longitude": {
        "min": -36.68,
        "max": -28.54
      }
    },
    "description": "Design software in a way that keeps as many options (for future decisions) open for as long as possible.",
    "link": "./detail-pages/design-architecture-options.html",
    "deepLink": "design-architecture-options"
  },
  {
    "name": "Predictive & Reflective Design",
    "coord": {
      "latitude": {
        "min": 76.01,
        "max": 77.57
      },
      "longitude": {
        "min": -24.13,
        "max": -13.07
      }
    },
    "description": "Predictive design: Designing for code that is yet to be written. What people usually think of as \"design.\" Reflective design: Designing based on code that has already been written in order to improve that code.",
    "link": "./detail-pages/predictive-and-reflective-designe.html",
    "deepLink": "predictive-and-reflective-designe"
  },
  {
    "name": "Feature Toggles",
    "coord": {
      "latitude": {
        "min": 75.73,
        "max": 76.69
      },
      "longitude": {
        "min": -35.19,
        "max": -27.48
      }
    },
    "description": "Feature toggles allow switching features on and off in software. Helps to achieve continuous delivery and reduces risks through releasing features gradually.",
    "link": "./detail-pages/feature-toggles.html",
    "deepLink": "feature-toggles"
  },
  {
    "name": "Team Architecture Workshops",
    "coord": {
      "latitude": {
        "min": 74.3,
        "max": 75.3
      },
      "longitude": {
        "min": -39.6,
        "max": -25.6
      }
    },
    "description": "Frequent workshop for the whole team to respond to how the architecture supported the implementation of features and to anticipate needed changes to the architecture for future features.",
    "link": "./detail-pages/team-architecture-workshops.html",
    "deepLink": "team-architecture-workshops"
  },
  {
    "name": "Design System",
    "coord": {
      "latitude": {
        "min": 77.5,
        "max": 78.75
      },
      "longitude": {
        "min": -45,
        "max": -40
      }
    },
    "description": "",
    "link": "./detail-pages/design-system.html",
    "deepLink": "design-system"
  },
  {
    "name": "Security by Design",
    "coord": {
      "latitude": {
        "min": 77.25,
        "max": 78.1
      },
      "longitude": {
        "min": -7,
        "max": 1.8
      }
    },
    "description": "",
    "link": "./detail-pages/security-by-design.html",
    "deepLink": "security-by-design"
  },
  {
    "name": "Simple Code",
    "coord": {
      "latitude": {
        "min": 81.3,
        "max": 81.84
      },
      "longitude": {
        "min": 20,
        "max": 27
      }
    },
    "description": "The code is clean if it can be understood easily – by everyone on the team. With understandability comes readability, changeability, extensibility and maintainability. All the things needed to keep software development going over a long time at a constant pace.",
    "link": "./detail-pages/simple-code.html",
    "deepLink": "simple-code"
  },
  {
    "name": "Coding Conventions",
    "coord": {
      "latitude": {
        "min": 80.76,
        "max": 81.36
      },
      "longitude": {
        "min": 12.41,
        "max": 23.09
      }
    },
    "description": "How the source code should look. A common style helps readability, understandability and navigability.",
    "link": "./detail-pages/coding-conventions.html",
    "deepLink": "coding-conventions"
  },
  {
    "name": "Continuous Refactoring",
    "coord": {
      "latitude": {
        "min": 79.93,
        "max": 80.59
      },
      "longitude": {
        "min": 19.61,
        "max": 29.93
      }
    },
    "description": "Improve quality aspects of the code in small steps without changing its behaviour before (preparatory), during (TDD-refactor) and after (simplify, make more comprehensible) adding new functionality.",
    "link": "./detail-pages/continuous-refactioring.html",
    "deepLink": "continuous-refactioring"
  },
  {
    "name": "Pair Programming/Pairing/Teaming(Mob Programming)",
    "coord": {
      "latitude": {
        "min": 77.91,
        "max": 79.07
      },
      "longitude": {
        "min": 14.95,
        "max": 29.93
      }
    },
    "description": "Two (or more) people work together to solve a problem in different roles: The Driver (the one writing) focuses on the details, the Navigator focuses on the big picture.",
    "link": "./detail-pages/pair-programming-pairing-mob-programming.html",
    "deepLink": "pair-programming-pairing-mob-programming"
  },
  {
    "name": "Push/PR Review",
    "coord": {
      "latitude": {
        "min": 77.57,
        "max": 78.79
      },
      "longitude": {
        "min": 6.81,
        "max": 12.3
      }
    },
    "description": "A peer team member reviews the changes before code and/or artefacts are merged into the shared repository.",
    "link": "./detail-pages/push-pr-review.html",
    "deepLink": "push-pr-review"
  },
  {
    "name": "Code Review",
    "coord": {
      "latitude": {
        "min": 80.13,
        "max": 81.08
      },
      "longitude": {
        "min": 7.18,
        "max": 12.5
      }
    },
    "description": "Systematic examination of source code to find mistakes overlooked during development. Kinds of reviews: walkthroughs, inspections.",
    "link": "./detail-pages/code-review.html",
    "deepLink": "code-review"
  },
  {
    "name": "Compile Time Safety over Tests",
    "coord": {
      "latitude": {
        "min": 75.17,
        "max": 76.96
      },
      "longitude": {
        "min": 28.32,
        "max": 36.5
      }
    },
    "description": "Checking correctness with the compiler should be given priority over testing. The feedback cycle is much quicker.",
    "link": "./detail-pages/compiling-time-safety-over-tests.html",
    "deepLink": "compiling-time-safety-over-tests"
  },
  {
    "name": "Test Driven Development",
    "coord": {
      "latitude": {
        "min": 69.5,
        "max": 70.9
      },
      "longitude": {
        "min": 5.36,
        "max": 16
      }
    },
    "description": "Short iterations of transforming part of a requirement into a failing test, writing just enough production code to make the test succeed and  refactoring the code base for simplicity.",
    "link": "./detail-pages/test-driven-development.html",
    "deepLink": "test-driven-development"
  },
  {
    "name": "Behaviour Driven Development",
    "coord": {
      "latitude": {
        "min": 70.7,
        "max": 72.9
      },
      "longitude": {
        "min": -5,
        "max": 3.5
      }
    },
    "description": "Encourage collaboration among developers, quality assurance experts and customer representatives in a software project by writing a testable definition how a part of the system should behave.",
    "link": "./detail-pages/behaviour-driven-development.html",
    "deepLink": "behaviour-driven-development"
  },
  {
    "name": "Process Boundary Tests",
    "coord": {
      "latitude": {
        "min": 72.05,
        "max": 74.02
      },
      "longitude": {
        "min": -11.08,
        "max": -5.3
      }
    },
    "description": "Test the boundary of a process by verifying that the outside can correctly communicate with the inside of the process. The triggered operation is simulated to decouple these tests from business logic changes.",
    "link": "./detail-pages/process-boundary-tests.html",
    "deepLink": "process-boundary-tests"
  },
  {
    "name": "Exploratory Testing",
    "coord": {
      "latitude": {
        "min": 70.34,
        "max": 72.1
      },
      "longitude": {
        "min": -19.47,
        "max": -12.44
      }
    },
    "description": "Simultaneous learning, test design, test execution and test result interpretation.",
    "link": "./detail-pages/exploratory-testing.html",
    "deepLink": "exploratory-testing"
  },
  {
    "name": "System Parts Interaction Tests",
    "coord": {
      "latitude": {
        "min": 71.3,
        "max": 73.55
      },
      "longitude": {
        "min": -23,
        "max": -15.2
      }
    },
    "description": "Tests that verify that the individual parts of the system (services, processes) can interact with each other and can be run locally.",
    "link": "./detail-pages/system-parts-interaction-tests.html",
    "deepLink": "system-parts-interaction-tests"
  },
  {
    "name": "System Smoke Testing",
    "coord": {
      "latitude": {
        "min": 71.34,
        "max": 73.5
      },
      "longitude": {
        "min": -29.5,
        "max": -24.75
      }
    },
    "description": "Test the most important functionalities after deployment to ensure basic functionality of the software system.",
    "link": "./detail-pages/system-smoke-testing.html",
    "deepLink": "system-smoke-testing"
  },
  {
    "name": "Scenario Tests",
    "coord": {
      "latitude": {
        "min": 70.82,
        "max": 71.38
      },
      "longitude": {
        "min": -37.35,
        "max": -26.41
      }
    },
    "description": "Test whole user scenarios at the system level.",
    "link": "./detail-pages/scenario-tests.html",
    "deepLink": "scenario-tests"
  },
  {
    "name": "Approval Tests",
    "coord": {
      "latitude": {
        "min": 70.05,
        "max": 70.7
      },
      "longitude": {
        "min": -36.95,
        "max": -26.08
      }
    },
    "description": "Compare current behaviour or test output against a validated previous run.",
    "link": "./detail-pages/approval-tests.html",
    "deepLink": "approval-tests"
  },
  {
    "name": "Defect Driven Testing",
    "coord": {
      "latitude": {
        "min": 69.25,
        "max": 69.88
      },
      "longitude": {
        "min": -27.6,
        "max": -12
      }
    },
    "description": "When a defect is found, it is reproduced by a test and then fixed. The defect cannot reoccur.",
    "link": "./detail-pages/defect-driven-testing.html",
    "deepLink": "defect-driven-testing"
  },
  {
    "name": "System Usability Scale (SUS)",
    "coord": {
      "latitude": {
        "min": 66.04,
        "max": 68.13
      },
      "longitude": {
        "min": -34.73,
        "max": -24.63
      }
    },
    "description": "Creates a score based on a set of standardised questions so that you can check if you improved the usability of the product with the product increment.",
    "link": "./detail-pages/system-usability-scale.html",
    "deepLink": "system-usability-scale"
  },
  {
    "name": "Usability Testing",
    "coord": {
      "latitude": {
        "min": 65.02,
        "max": 67.3
      },
      "longitude": {
        "min": -17.79,
        "max": -12.19
      }
    },
    "description": "Usability Testing is a type of testing done from an end-user’s perspective to determine if the system is easily usable.",
    "link": "./detail-pages/usability-testing.html",
    "deepLink": "usability-testing"
  },
  {
    "name": "Incidents",
    "coord": {
      "latitude": {
        "min": 66.21,
        "max": 67.3
      },
      "longitude": {
        "min": 18.5,
        "max": 27.5
      }
    },
    "description": "Failures and warnings from the production environment and user reports.",
    "link": "./detail-pages/incidents.html",
    "deepLink": "incidents"
  },
  {
    "name": "System Behaviour Monitoring",
    "coord": {
      "latitude": {
        "min": 69.2,
        "max": 71.1
      },
      "longitude": {
        "min": 17.2,
        "max": 27
      }
    },
    "description": "Monitors the production environment with telemetry about performance, load and reliability.",
    "link": "./detail-pages/system-behaviour-monitoring.html",
    "deepLink": "system-behaviour-monitoring"
  },
  {
    "name": "Test Tenants",
    "coord": {
      "latitude": {
        "min": 58.2,
        "max": 61.28
      },
      "longitude": {
        "min": 17.5,
        "max": 23.72
      }
    },
    "description": "A tenant on the productive environment that can be used to test new and existing functionality without effect on real tenants.",
    "link": "./detail-pages/test-tenants.html",
    "deepLink": "test-tenants"
  },
  {
    "name": "Testing in Production",
    "coord": {
      "latitude": {
        "min": 60.5,
        "max": 62.6
      },
      "longitude": {
        "min": 25.2,
        "max": 35
      }
    },
    "description": "Executing tests on the production environment.",
    "link": "./detail-pages/testing-in-production.html",
    "deepLink": "testing-in-production"
  },
  {
    "name": "Performance Testing",
    "coord": {
      "latitude": {
        "min": 64.1,
        "max": 65.81
      },
      "longitude": {
        "min": -0.7,
        "max": 10
      }
    },
    "description": "Determines how the system performs regarding responsiveness and stability under a particular workload.",
    "link": "./detail-pages/performance-testing.html",
    "deepLink": "performance-testing"
  },
  {
    "name": "Load Testing",
    "coord": {
      "latitude": {
        "min": 62.57,
        "max": 63.58
      },
      "longitude": {
        "min": -0.51,
        "max": 10.54
      }
    },
    "description": "Simulates multiple users accessing the system concurrently and measures the quality of service.",
    "link": "./detail-pages/load-testing.html",
    "deepLink": "load-testing"
  },
  {
    "name": "Security Testing",
    "coord": {
      "latitude": {
        "min": 60.65,
        "max": 62.48
      },
      "longitude": {
        "min": -7.47,
        "max": 0.78
      }
    },
    "description": "Reveals flaws in the security mechanisms of the system to protect data and maintain functionality as intended.",
    "link": "./detail-pages/security-testing.html",
    "deepLink": "security-testing"
  },
  {
    "name": "Interoperability Testing",
    "coord": {
      "latitude": {
        "min": 57.9,
        "max": 59.88
      },
      "longitude": {
        "min": -10.95,
        "max": 1.65
      }
    },
    "description": "Determines how well the software inter-operates with other software components, software or systems.",
    "link": "./detail-pages/interoperability-testing.html",
    "deepLink": "interoperability-testing"
  },
  {
    "name": "Compatibility Testing",
    "coord": {
      "latitude": {
        "min": 54.72,
        "max": 57.92
      },
      "longitude": {
        "min": -7,
        "max": 2.03
      }
    },
    "description": "Determines how compatible the software is with different platforms, environments, operating systems, browsers, other systems, …",
    "link": "./detail-pages/compatibility-testing.html",
    "deepLink": "compatibility-testing"
  },
  {
    "name": "Reliability Testing",
    "coord": {
      "latitude": {
        "min": 55.78,
        "max": 58.83
      },
      "longitude": {
        "min": 7.5,
        "max": 14
      }
    },
    "description": "Determines the reliability of the software (probability of failure-free software operation for a specific period of time in a specific environment).",
    "link": "./detail-pages/reliability-testing.html",
    "deepLink": "reliability-testing"
  },
  {
    "name": "Installability Testing",
    "coord": {
      "latitude": {
        "min": 52.77,
        "max": 54.97
      },
      "longitude": {
        "min": 5.9,
        "max": 16
      }
    },
    "description": "Tests the installation procedures of the software (install, uninstall, partial and full upgrade).",
    "link": "./detail-pages/installability-testing.html",
    "deepLink": "installability-testing"
  },
  {
    "name": "Version Control",
    "coord": {
      "latitude": {
        "min": 67.67,
        "max": 69.9
      },
      "longitude": {
        "min": 48,
        "max": 54
      }
    },
    "description": "Manage changes to source code and other artefacts so that older versions can be reproduced.",
    "link": "./detail-pages/version-control.html",
    "deepLink": "version-control"
  },
  {
    "name": "Versioning (Product)",
    "coord": {
      "latitude": {
        "min": 67.44,
        "max": 69
      },
      "longitude": {
        "min": 57,
        "max": 66.47
      }
    },
    "description": "The version number of the product (and its parts) identify the version of the source code and other artefacts it was built from.",
    "link": "./detail-pages/versioning.html",
    "deepLink": "versioning"
  },
  {
    "name": "Continuous Integration",
    "coord": {
      "latitude": {
        "min": 61.87,
        "max": 64.61
      },
      "longitude": {
        "min": 46,
        "max": 54.04
      }
    },
    "description": "Members of a team integrate their work frequently (multiple integrations per day). Each integration is verified by an automated build (including test) to detect integration errors as quickly as possible.",
    "link": "./detail-pages/continuous-integration.html",
    "deepLink": "continuous-integration"
  },
  {
    "name": "Continuous Deployment",
    "coord": {
      "latitude": {
        "min": 61.34,
        "max": 63.63
      },
      "longitude": {
        "min": 56,
        "max": 66.72
      }
    },
    "description": "Extension to Continuous Integration to deploy the integrated software to a test or production environment automatically. And in case of failure, trigger human intervention.",
    "link": "./detail-pages/continuous-deployment.html",
    "deepLink": "continuous-deployment"
  },
  {
    "name": "Configuration Management",
    "coord": {
      "latitude": {
        "min": 58.67,
        "max": 61.22
      },
      "longitude": {
        "min": 44.1,
        "max": 56.16
      }
    },
    "description": "Establishing and maintaining consistency of a product's performance, functional, and physical attributes with its requirements, design, and operational information throughout its life.",
    "link": "./detail-pages/configuration-management.html",
    "deepLink": "configuration-management"
  }
]
