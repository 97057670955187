[
  {
    "name": "Product Quality",
    "coord": {
      "latitude": {
        "min": 83.5,
        "max": 84.05
      },
      "longitude": {
        "min": -154,
        "max": -138.6
      }
    },
    "description": "",
    "link": "./detail-pages/product-quality.html",
    "deepLink": "product-quality"
  },
  {
    "name": "Functional Suitability",
    "coord": {
      "latitude": {
        "min": 83.18,
        "max": 83.41
      },
      "longitude": {
        "min": -154,
        "max": -138.6
      }
    },
    "description": "Capability of a product to provide functions that meet stated and implied needs of intended users when it is used under specified conditions.",
    "link": "./detail-pages/functional-suitability.html",
    "deepLink": "functional-suitability"
  },
  {
    "name": "Performance efficiency",
    "coord": {
      "latitude": {
        "min": 82.88,
        "max": 83.12
      },
      "longitude": {
        "min": -154,
        "max": -137.38
      }
    },
    "description": "Performance relative to the amount of resources used under stated conditions.Performance relative to the amount of resources used under stated conditions.",
    "link": "./detail-pages/performance-efficiency.html",
    "deepLink": "performance-efficiency"
  },
  {
    "name": "Compatibility",
    "coord": {
      "latitude": {
        "min": 82.58,
        "max": 82.82
      },
      "longitude": {
        "min": -154,
        "max": -143.27
      }
    },
    "description": "Capability of a product to exchange information with other products, and/or to perform its required functions while sharing the same common environment and resources.",
    "link": "./detail-pages/compatibility.html",
    "deepLink": "compatibility"
  },
  {
    "name": "Interaction capability",
    "coord": {
      "latitude": {
        "min": 82.28,
        "max": 82.53
      },
      "longitude": {
        "min": -154,
        "max": -145.8
      }
    },
    "description": "Capability of a product to be interacted with by specified users to exchange information between a user and a system via the user interface to complete the intended task.",
    "link": "./detail-pages/interaction-capability.html",
    "deepLink": "interaction-capability"
  },
  {
    "name": "Reliability",
    "coord": {
      "latitude": {
        "min": 81.95,
        "max": 82.21
      },
      "longitude": {
        "min": -154,
        "max": -145.2
      }
    },
    "description": "Capability of a product to perform specified functions under specified conditions for a specified period of time without interruptions and failures.",
    "link": "./detail-pages/reliability.html",
    "deepLink": "reliability"
  },
  {
    "name": "Security",
    "coord": {
      "latitude": {
        "min": 81.59,
        "max": 81.89
      },
      "longitude": {
        "min": -154,
        "max": -146.44
      }
    },
    "description": "Capability of a product to protect information and data so that persons or other products have the degree of data access appropriate to their types and levels of authorization, and to defend against attack patterns by malicious actors.",
    "link": "./detail-pages/security.html",
    "deepLink": "security"
  },
  {
    "name": "Maintainability",
    "coord": {
      "latitude": {
        "min": 81.23,
        "max": 81.55
      },
      "longitude": {
        "min": -154,
        "max": -142.15
      }
    },
    "description": "Capability of a product to be modified by the intended maintainers with effectiveness and efficiency.",
    "link": "./detail-pages/maintainability.html",
    "deepLink": "maintainability"
  },
  {
    "name": "Flexibility",
    "coord": {
      "latitude": {
        "min": 80.88,
        "max": 81.2
      },
      "longitude": {
        "min": -154,
        "max": -145
      }
    },
    "description": "Capability of a product to be adapted to changes in its requirements, contexts of use, or system environment.",
    "link": "./detail-pages/flexibility.html",
    "deepLink": "flexibility"
  },
  {
    "name": "Safety",
    "coord": {
      "latitude": {
        "min": 80.45,
        "max": 80.8
      },
      "longitude": {
        "min": -154,
        "max": -145
      }
    },
    "description": "Capability of a product under defined conditions to avoid a state in which human life, health, property, or the environment is endangered. ",
    "link": "./detail-pages/safety.html",
    "deepLink": "safety"
  },
  {
    "name": "Expectation",
    "coord": {
      "latitude": {
        "min": 83.18,
        "max": 83.41
      },
      "longitude": {
        "min": -125.2,
        "max": -115.6
      }
    },
    "description": "Degree to which actual outcomes match (or even exceed) the expectations of the stakeholders.",
    "link": "./detail-pages/expectation.html",
    "deepLink": "expectation"
  },
  {
    "name": "Effectiveness",
    "coord": {
      "latitude": {
        "min": 82.88,
        "max": 83.12
      },
      "longitude": {
        "min": -125.2,
        "max": -114.7
      }
    },
    "description": "Degree to which actual outcomes solve the needs of the stakeholders.",
    "link": "./detail-pages/effectiveness.html",
    "deepLink": "effectiveness"
  },
  {
    "name": "Efficiency",
    "coord": {
      "latitude": {
        "min": 82.58,
        "max": 82.82
      },
      "longitude": {
        "min": -125.2,
        "max": -117
      }
    },
    "description": "Degree to which the resources of the stakeholders (time, money) are not wasted. ",
    "link": "./detail-pages/efficiency.html",
    "deepLink": "efficiency"
  },
  {
    "name": "Predictability",
    "coord": {
      "latitude": {
        "min": 82.28,
        "max": 82.53
      },
      "longitude": {
        "min": -125.2,
        "max": -114.8
      }
    },
    "description": "Degree to which actual outcomes can be predicted.",
    "link": "./detail-pages/predictability.html",
    "deepLink": "predictability"
  },
  {
    "name": "Reliability",
    "coord": {
      "latitude": {
        "min": 81.95,
        "max": 82.21
      },
      "longitude": {
        "min": -125.2,
        "max": -116.8
      }
    },
    "description": "Degree to which the stakeholders can rely on the provider's continuous quality of outcomes.",
    "link": "./detail-pages/service-reliability.html",
    "deepLink": "service-reliability"
  },
  {
    "name": "Responsiveness",
    "coord": {
      "latitude": {
        "min": 81.59,
        "max": 81.89
      },
      "longitude": {
        "min": -125.2,
        "max": -113.2
      }
    },
    "description": "Degree to which the service provider is willing and able to respond promptly and helpfully.",
    "link": "./detail-pages/responsiveness.html",
    "deepLink": "responsiveness"
  },
  {
    "name": "Assurance",
    "coord": {
      "latitude": {
        "min": 81.23,
        "max": 81.55
      },
      "longitude": {
        "min": -125.2,
        "max": -116.5
      }
    },
    "description": "Degree to which the service provider is able to convey trust and confidence.",
    "link": "./detail-pages/assurance.html",
    "deepLink": "assurance"
  },
  {
    "name": "Empathy",
    "coord": {
      "latitude": {
        "min": 80.88,
        "max": 81.2
      },
      "longitude": {
        "min": -125.2,
        "max": -117.4
      }
    },
    "description": "Degree to which the service provider is able to be approachable, caring, understanding and to relate to customer needs.",
    "link": "./detail-pages/empathy.html",
    "deepLink": "empathy"
  },
  {
    "name": "Tangibles",
    "coord": {
      "latitude": {
        "min": 80.51,
        "max": 80.83
      },
      "longitude": {
        "min": -125.2,
        "max": -117
      }
    },
    "description": "Degree to which physical facilities, equipment, personnel and communication materials are perceivable.",
    "link": "./detail-pages/tangibles.html",
    "deepLink": "tangibles"
  }
]
